<template>
  <validation-observer
    :key="componentKey"
    ref="simpleRules"
  >
    <b-form @submit.prevent>
      <b-card>
        <h2 class="text-center mb-2">
          Estabelecimento
        </h2>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="CPF/ CNPJ*"
              label-for="tax_ID"
            >
              <validation-provider
                #default="{ errors }"
                name="CPF/ CNPJ"
                rules="required|tax-id"
              >
                <b-form-input
                  id="tax_id"
                  v-model="merchant.tax_id"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  :state="errors.length > 0 ? false : null"
                  placeholder="CPF/CNPJ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Nome*"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="merchant.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nome"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Razão Social*"
              label-for="merchant_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Razão Social"
                rules="required"
              >
                <b-form-input
                  id="merchant_name"
                  v-model="merchant.legal_tax_name"
                  placeholder="Razão Social"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Email*"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="merchant.email"
                  placeholder="Email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <h2 class="text-center mb-2">
          Endereço
        </h2>

        <b-row>
          <b-col
            sm="12"
            lg="3"
            md="12"
            offset-xl="0"
          >
            <b-form-group
              label="País"
              label-for="v-country"
            >
              <validation-provider
                #default="{ errors }"
                name="País"
                rules="required"
              >
                <b-form-select
                  id="v-country"
                  v-model="merchant.address.country"
                  :state="errors.length > 0 ? false : null"
                  disabled
                >
                  <template v-slot:first>
                    <b-form-select-option value="BR">
                      Brasil
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="3"
            md="12"
            offset-xl="0"
          >
            <b-form-group
              label="CEP"
              label-for="v-cep"
            >
              <validation-provider
                #default="{ errors }"
                name="CEP"
                :rules="`cep: ${merchant.address.state}`"
              >
                <b-form-input
                  id="v-cep"
                  v-model="merchant.address.postal_code"
                  v-mask="'#####-###'"
                  placeholder="CEP"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="3"
            md="12"
            offset-xl="0"
          >
            <b-form-group
              label="Estado"
              label-for="v-state"
            >
              <b-form-input
                id="v-state"
                v-model="merchant.address.state"
                placeholder="Estado"
                :readonly="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="3"
            md="12"
            offset-xl="0"
          >
            <b-form-group
              label="Cidade"
              label-for="v-city"
            >
              <b-form-input
                id="v-city"
                v-model="merchant.address.city"
                placeholder="Cidade"
                :readonly="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="6"
            offset-xl="0"
          >
            <b-form-group
              label="Endereço"
              label-for="v-address"
            >
              <b-form-input
                id="v-address"
                v-model="merchant.address.address"
                placeholder="Endereço"
                :readonly="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="6"
            offset-xl="0"
          >
            <b-form-group
              label="Bairro"
              label-for="v-district"
            >
              <b-form-input
                id="v-district"
                v-model="merchant.address.neighborhood"
                :readonly="true"
                placeholder="Bairro"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="3"
            offset-xl="0"
          >
            <b-form-group
              label="Número"
              label-for="v-number"
            >
              <validation-provider
                #default="{ errors }"
                name="Numero"
                :rules="merchant.address.postal_code && 'required'"
              >
                <b-form-input
                  id="v-number"
                  v-model="merchant.address.number"
                  type="number"
                  placeholder="Número"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="3"
          >
            <b-form-group
              label="Complemento"
              label-for="v-complemento"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="v-complemento"
                  v-model="merchant.address.complement"
                  placeholder="Complemento"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          class="m-auto d-block"
          @click="validationForm"
        >
          {{ buttonText }}
        </b-button>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import {
    BButton, BCard, BForm, BFormGroup, BFormInput, BInputGroup, BCol,
    BRow, BFormSelect, BFormSelectOption,
} from 'bootstrap-vue';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

import PostalCodeService from '@/services/webservices/PostalCodeService';

import '@validations';
import WebService from '@/services/osiris/WebService';

export default {
    components: {
        BButton,
        BCard,
        BForm,
        BFormGroup,
        BFormInput,
        ValidationObserver,
        ValidationProvider,
        BInputGroup,
        BCol,
        BRow,
        BFormSelect,
        BFormSelectOption,
    },

    props: {
        merchant: {
            type: Object,
            required: true,
        },

        buttonText: {
            type: String,
            default: () => 'Enviar',
        },
    },

    data() {
        return {
            componentKey: 0,
        };
    },

    watch: {
        'merchant.address.postal_code': {
            handler(value) {
                if (value.length === 9) {
                    this.getAddressDataByPostalCode(value.replace(/-/g, ''));
                }
            },
        },

        'merchant.tax_id': {
            handler(value) {
                if (value.length === 18) {
                    this.getDataByCNPJ(value.replace(/[^\d]+/g, ''));
                }
            },
        },
    },

    methods: {
        async getAddressDataByPostalCode(postalCode) {
            const { data, status } = await PostalCodeService.execute(postalCode);

            if (status !== 200) {
                return;
            }

            const {
                address, city, state, district,
            } = data;

            this.merchant.address = {
                ...this.merchant.address,
                address,
                city,
                state,
                neighborhood: district,
            };
        },

        async getDataByCNPJ(cnpj = '') {
            const { data, status } = await WebService.findCNPJ(cnpj);

            if (status !== 200) return;

            const {
                email, name, legal_tax_name: legalTaxName, address,
            } = data;

            this.merchant.email = email;
            this.merchant.legal_tax_name = legalTaxName;
            this.merchant.name = name;
            this.merchant.address = address;

            this.componentKey += 1;
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$emit('click-button', this.merchant);
                }
            });
        },
    },
};
</script>
